import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {from, last, mergeScan, Observable, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';

export interface DynamicRequestInterceptor {
  updateRequest(request: HttpRequest<unknown>): Promise<HttpRequest<unknown>>;
}

/**
 * Allows registering http interceptors from lazy loaded angular modules.
 */
@Injectable()
export class DynamicInterceptorProxyInterceptor implements HttpInterceptor {
  private interceptors = new Map<string, DynamicRequestInterceptor>();

  public registerRequestInterceptor(name: string, interceptor: DynamicRequestInterceptor): void {
    this.interceptors.set(name, interceptor);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return of(...this.interceptors.values()).pipe(
      mergeScan((req, interceptor) => from<Promise<HttpRequest<unknown>>>(interceptor.updateRequest(req)), request),
      last(undefined, request),
      mergeMap(req => next.handle(req))
    );
  }

  removeRequestInterceptor(name: string) {
    this.interceptors.delete(name);
  }
}
